<template>
  <ui-modal
    v-if="isShowRiskLevelModal"
    width="592"
    @close="hideRiskLevelModal()"
  >
    <div class="w-7/12 flex pt-20 pb-20 flex-col items-center m-auto">
      <h3 class="text-4.5xl font-semibold">Set the Risk level</h3>
      <p
        class="mt-5 text-sm font-normal leading-tight text-gray-600 text-center"
      >
        You can update the risk level at any <br />
        time on the Supplier profile page
      </p>
      <supplier-risk-level class="w-full mt-6" />
      <ui-button
        class="mt-7"
        oval
        width="240"
        :button-type="ButtonType.Blue"
        @click="createAssessment"
      >
        Create an assessment
      </ui-button>

      <span
        class="mt-6 underline text-sm leading-tight cursor-pointer"
        @click="hideRiskLevelModal()"
      >
        Skip
      </span>
    </div>
  </ui-modal>

  <div v-if="isLoading">
    <!-- Loading... -->
  </div>

  <div v-else>
    <div class="-mx-3 px-3 pt-7 pb-4 rounded shadow-card-risk">
      <p class="text-sm leading-tight">
        Exposure identifies the impact of the supplier on the mission of the
        function.
        <br />
        The higher the dependency or the penetration, the higher the risk.
      </p>

      <div class="grid grid-cols-9 mt-4 gap-5">
        <div class="col-span-3">
          <div class="pb-2.5">
            <p
              class="flex justify-center border border-gray-300 rounded-xl text-sm leading-4 font-bold py-2"
            >
              Dependency
            </p>
          </div>

          <ui-select
            :disabled="isLimited || !plan?.risk_management"
            :items="INFO_NUMBER_SELECT_OPTIONS"
            placeholder="Dependency"
            :model-value="supplier?.dependency"
            @update:model-value="changeDependency"
          />
        </div>

        <div class="col-span-3">
          <div class="pb-2.5">
            <p
              class="flex justify-center border border-gray-300 rounded-xl text-sm leading-4 font-bold py-2"
            >
              Penetration
            </p>
          </div>

          <ui-select
            :disabled="isLimited || !plan?.risk_management"
            :items="INFO_NUMBER_SELECT_OPTIONS"
            :model-value="supplier?.penetration"
            @update:model-value="changePenetration"
            placeholder="Penetration"
          />
        </div>
        <div class="col-span-1 flex items-center justify-center">
          <span class="font-medium"> = </span>
        </div>
        <div class="col-span-2 relative">
          <div class="pb-2.5">
            <p
              class="flex justify-center border border-gray-300 rounded-xl text-sm leading-4 font-bold py-2"
            >
              Risk exposure
            </p>
          </div>
          <supplier-exposure
            class="w-full h-13"
            lg
            :exposure="supplier?.exposure"
          />
        </div>
      </div>
    </div>

    <div class="grid grid-cols-3 py-6">
      <div class="grid col-span-2 grid-cols-2 gap-x-5 gap-y-3">
        <div class="flex items-center py-0.5 rounded-lg bg-gray-200 h-16 px-4">
          <div class="w-13">
            <span
              class="text-blue-700 bg-blue-50 font-semibold text-2xs leading-3 px-1 py-1.5 rounded-sm flex items-center justify-center"
            >
              Low
            </span>
          </div>
          <p class="ml-4 text-sm leading-4 font-medium">
            Not critical to the realisation
            <br />
            of the mission
          </p>
        </div>

        <div class="flex items-center py-0.5 rounded-lg bg-gray-200 h-16 px-4">
          <div class="w-13">
            <span
              class="text-blue-700 bg-blue-50 font-semibold text-2xs leading-3 px-1 py-1.5 rounded-sm flex items-center justify-center"
            >
              Low
            </span>
          </div>
          <p class="ml-4 text-sm leading-4 font-medium">
            No access confidential or
            <br />
            limited access to non data
            <br />
            or infrastructure
          </p>
        </div>

        <div class="flex items-center py-0.5 rounded-lg bg-gray-200 h-16 px-4">
          <div class="w-13">
            <span
              class="text-yellow-darker bg-yellow-50 font-semibold text-2xs leading-3 px-1 py-1.5 rounded-sm flex items-center justify-center"
            >
              Medium
            </span>
          </div>
          <p class="ml-4 text-sm leading-4 font-medium">
            Integral to the realisation of the <br />
            mission with possible <br />
            substitution
          </p>
        </div>

        <div class="flex items-center py-0.5 rounded-lg bg-gray-200 h-16 px-4">
          <div class="w-13">
            <span
              class="text-yellow-darker bg-yellow-50 font-semibold text-2xs leading-3 px-1 py-1.5 rounded-sm flex items-center justify-center"
            >
              Medium
            </span>
          </div>
          <p class="ml-4 text-sm leading-4 font-medium">
            Limited access to confidential <br />
            data or infrastructure
          </p>
        </div>

        <div class="flex items-center py-0.5 rounded-lg bg-gray-200 h-16 px-4">
          <div class="w-13">
            <span
              class="text-red-darker bg-red-50 font-semibold text-2xs leading-3 px-1 py-1.5 rounded-sm flex items-center justify-center"
            >
              High
            </span>
          </div>
          <p class="ml-4 text-sm leading-4 font-medium">
            Integral to the realisation of the <br />
            mission without possible <br />
            short-term substitution
          </p>
        </div>

        <div class="flex items-center py-0.5 rounded-lg bg-gray-200 h-16 px-4">
          <div class="w-13">
            <span
              class="text-red-darker bg-red-50 font-semibold text-2xs leading-3 px-1 py-1.5 rounded-sm flex items-center justify-center"
            >
              High
            </span>
          </div>
          <p class="ml-4 text-sm leading-4 font-medium">
            Priviledge access to confidential <br />
            data, Pll or infrastructure
          </p>
        </div>
      </div>

      <div class="flex col-span-1 items-end justify-end pl-3">
        <ui-button
          width="200"
          oval
          :button-type="ButtonType.Blue"
          @click="setRiskLevel"
        >
          Set risk level
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useShowError from '@/core/hooks/useShowError';

import clientsApi from '@/services/api/clients-api';
import { INFO_NUMBER_SELECT_OPTIONS } from '@/core/constants/info_number';

import UiSelect from '@/components/ui/UiSelect.vue';
import UiButton from '@/components/ui/UiButton.vue';

import { ButtonType } from '@/enums/button';
import { ROUTER_NAMES } from '@/router/router.enum';
import { ErrorEnum } from '@/core/enums/errors';

import { useToast } from 'vue-toastification';

import SupplierExposure from '@/modules/client/components/suppliers/SupplierExposure.vue';
import UiModal from '@/components/ui/UiModal.vue';
import SupplierRiskLevel from '@/modules/client/components/suppliers/SupplierRiskLevel.vue';

export default {
  components: {
    UiSelect,
    SupplierExposure,
    UiButton,
    UiModal,
    SupplierRiskLevel,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const supplier = inject('supplier');
    const supplierUpdate = inject('supplierUpdate');
    const toast = useToast();

    const isShowRiskLevelModal = ref(false);

    const isLoading = computed(() => supplier?.value === null);
    const isLimited = computed(() => store.getters.isLimited);
    const isFreeTrial = computed(() => store.getters.isFreeTrial);
    const subscription = computed(() => store.state.subscription.subscription);
    const plan = computed(() => store.state.subscription.subscription.plan);

    function changeDependency(val) {
      onUpdated({ dependency: val });
    }

    function changePenetration(val) {
      onUpdated({ penetration: val });
    }

    async function onUpdated(data) {
      if (supplier.value) {
        try {
          let result = await clientsApi.editSupplier(supplier?.value.id, data);
          supplierUpdate(result);
        } catch (err) {
          const errorText = Array.isArray(Object.values(err)[0])
            ? Object.values(err)[0][0]
            : Object.values(err)[0];

          toast.error(errorText);
        }
      }
    }

    function hideRiskLevelModal() {
      isShowRiskLevelModal.value = false;
    }

    function createAssessment() {
      if (
        !isLimited.value &&
        (subscription.value.available.pro_evaluations ||
          subscription.value.available.self_evaluations ||
          subscription.value.available.self_evaluations === null)
      ) {
        router.push({
          name: ROUTER_NAMES.CREATE_ASSESSMENT,
          query: { supplier: supplier?.value.id },
        });
      } else {
        hideRiskLevelModal();

        useShowError({
          error: ErrorEnum.PERMISSION_ERROR,
          cta: ErrorEnum.ASSESS_SUPPLIER,
          showToast: false,
        });
      }
    }

    function setRiskLevel() {
      if (!isLimited.value) {
        isShowRiskLevelModal.value = true;
      } else {
        useShowError({
          error: ErrorEnum.PERMISSION_ERROR,
          cta: ErrorEnum.RISK_ERROR,
          showToast: false,
        });
      }
    }

    return {
      plan,
      isLimited,
      isFreeTrial,
      isShowRiskLevelModal,
      supplier,
      isLoading,
      INFO_NUMBER_SELECT_OPTIONS,
      ButtonType,

      changePenetration,
      changeDependency,

      hideRiskLevelModal,
      createAssessment,
      setRiskLevel,
    };
  },
};
</script>
