<template>
  <div class="flex-1 flex-col">
    <p class="text-sm leading-tight font-medium">Risk level</p>

    <ui-select
      :disabled="isLimited"
      class="mt-2"
      height="44"
      :model-value="supplier.risk_level"
      :items="SUPPLIER_RATING_LIST_OPTIONS"
      @update:model-value="changeSupplierRating"
      placeholder="Change risk"
    >
      <template #value="{ item }">
        <p
          :class="{
            'text-red': item.value === SupplierRatingEnum.HIGH_RISK,
            'text-blue-700': item.value === SupplierRatingEnum.TOLERABLE,
            'text-yellow-darker':
              item.value === SupplierRatingEnum.MODERATE_RISK,
          }"
        >
          {{ item.label }}
        </p>
      </template>
    </ui-select>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import useShowError from "@/core/hooks/useShowError";

import clientsApi from "@/services/api/clients-api";
import UiSelect from "@/components/ui/UiSelect.vue";
import { SUPPLIER_RATING_LIST_OPTIONS } from "@/core/constants/suppliers";
import { SupplierRatingEnum } from "@/core/enums/suppliers";
import { ErrorEnum } from "@/core/enums/errors";

export default {
  components: {
    UiSelect,
  },

  setup() {
    const supplier = inject("supplier");
    const supplierUpdate = inject("supplierUpdate");

    const store = useStore();

    const isLimited = computed(() => store.getters.isLimited);

    async function changeSupplierRating(risk_level) {
      if (!isLimited.value) {
        let result = await clientsApi.editSupplier(supplier.value.id, {
          risk_level: risk_level,
        });
        supplierUpdate(result);
      } else {
        useShowError({
          error: ErrorEnum.PERMISSION_ERROR,
          cta: ErrorEnum.NEED_MORE_FUTURES,
          showToast: false,
        });
      }
    }

    return {
      supplier,
      changeSupplierRating,
      SupplierRatingEnum,
      SUPPLIER_RATING_LIST_OPTIONS,
      isLimited,
    };
  },
};
</script>
